export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.brands.text',
        icon: 'ri-global-line',
        link: '/brands'
    },
    {
        id: 4,
        label: 'menuitems.categories.text',
        icon: 'ri-windows-line',
        link: '/categories'
    },
    {
        id: 5,
        label: 'menuitems.users.text',
        icon: 'ri-group-line',
        link: '/user/all'
    },
    {
        id: 7,
        label: 'menuitems.ecommerce.text',
        icon: 'ri-shopping-cart-line',
        subItems: [
            {
                id: 6,
                label: 'menuitems.ecommerce.list.products',
                link: '/ecommerce/products'
            },
            {
                id: 7,
                label: 'menuitems.order.text',
                link: '/ecommerce/orders'
            },
            {
                id: 15,
                label: 'menuitems.shippingFee.text',
                link: '/ecommerce/settings'
            },
        ]
    },
    {
        id: 8,
        label: 'menuitems.trades.text',
        icon: 'ri-funds-box-line',
        subItems: [
     
            {
                id: 1,
                label: 'menuitems.trades.text',
                link: '/ecommerce/trades'
            },
            {
                id: 2,
                label: 'menuitems.inProgress.text',
                link: '/ecommerce/trades-in-progress'
            },
            {
                id: 3,
                label: 'menuitems.tradeHistory.text',
                link: '/ecommerce/trades-history'
            },
            
           
        ]
    },
    {
        id: 9,
        label: 'menuitems.coupons.text',
        icon: 'ri-gift-2-line',
        link: '/coupons'
    },
    {
        id: 0,
        label: 'menuitems.settings.text',
        icon: 'ri-settings-line',
        link: '/settings'
    },
    {
        id: 18,
        isLayout: true
    },

    {
        id: 80,
        label: 'menuitems.support.text',
        isTitle: true
    },
    {
        id: 81,
        label: 'menuitems.support.text',
        icon: ' ri-customer-service-2-line',
        link: '/support'
    },
    {
        id: 90,
        label: 'menuitems.notification.text',
        isTitle: true
    },
    {
        id: 92,
        label: 'menuitems.broadcast.text',
        icon: 'ri-send-plane-line',
        link: '/broadcast'
    }

]